/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
         function fullscreen(){
            height = $(window).height();
            height = height - 134;
            $('.tall .wrapit').css({
                height: height
            });
        }


        $('.mob-nav').click(function(){
          $('.mobile-nav').addClass('go');
        });

        $('.closeit').click(function(){
          $('.mobile-nav').removeClass('go');
        });



       // Start Gravity forms JS 

       function gfformFocus(){
          $('.form-wrap input, .form-wrap textarea').focus(function(e){
            e.preventDefault();
            $(this).parent().prev('label').addClass('focus');
          });
        }

        function gfformFocusOut(){
          $('.form-wrap input, .form-wrap textarea').on("blur", function(e){
            e.preventDefault();
            if(!$(this).val()){
              $(this).parent().prev('label').removeClass('focus');
            }
            
          });
        } 

        function gfInitForm(){
          $('.form-wrap input').each(function(){
            if($(this).val()){
              $(this).parent().prev('label').addClass('focus');
            }
          });
        }

        jQuery(document).bind('gform_post_render', function(){
          gfInitForm();
          gfformFocus();
          gfformFocusOut();
        });

        gfformFocus();
        gfformFocusOut();


        // End Gravity forms JS
      
        fullscreen();

        $(window).resize(function() {
           fullscreen();         
        });

        $('document').ready(function(){
          $('body').addClass('go');
          $('.wrapit').addClass('go');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('.bxslider').bxSlider({
        controls: true,
        nextText: '',
        prevText: ''
       });
      }
    },

    'about': {
      init: function() {
        // JavaScript to be fired on the home page
        var waypoint = new Waypoint({
          element: document.getElementById('main-b'),
          handler: function(direction) {
            //if (direction == 'down') {            
              $(".music").addClass('go');
              $(".ac").addClass('go');
              $(".lady").addClass('go');


            //} else {
            //}
          },
           offset: '55%'
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'services': {
      init: function() {
        var rolling = false;
        // JavaScript to be fired on the home page
        var waypoint = new Waypoint({
          element: document.getElementById('servnav'),
          handler: function(direction) {
            if (direction == 'down') {
              
              $("#servnav").addClass('stuck');
              $("#services").css('margin-top', "68px");
            } else {
              $("#servnav").removeClass('stuck');
              $("#services").css('margin-top', "0");
            }
          },
           offset: 10
        });

        $(window).scroll(function() {

            var windscroll = $(window).scrollTop();
            if (windscroll >= 100) {
                $('.service').each(function(i) {                  
                    if ($(this).position().top <= windscroll + 100) {
                        $('.sticky li a.active').removeClass('active');
                        if(rolling === "true")
                          return false;
                        $('.sticky li a').eq(i).addClass('active');
                    }
                });

            } else {

                $('.sticky li a.active').removeClass('active');
                $('.sticky li a:first').addClass('active');
            }



        }).scroll();​

        // navigation scroll
        $(".sticky li").click(function(e) {
            e.preventDefault();
            clicked = $(this);
            scrollitDown(clicked, "true");
          
        });

                // navigation scroll
        $(".btn-white-b").click(function(e) {
            e.preventDefault();
            clicked = $(this);
            scrollitDown(clicked, "false");
          
        });


        function scrollitDown(clicked, rolling){
            rolling = rolling;
            
            $(clicked).find('a').addClass('active');
            target = $(clicked).attr('data-nav');
            $('html, body').animate({
                scrollTop: $("#"+target).offset().top +-110
            }, 2000, function() {
               rolling = "false";
            });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('.bxslider').bxSlider({
        controls: true,
        nextText: '',
        prevText: ''
       });
      }
    },
    // About us page, note the change from about-us to about_us.
    'careers': {
      init: function() {
        $('.close-it').click(function() {
          $(this).removeClass('go');
          prime = $(this).closest('.position');
          $(prime).removeClass('open');
          $(prime).find('.details').removeClass('go');
          $(prime).find('.close-it').removeClass('go');
          $(prime).find('.apply').hide();
          $(prime).find('.view-j').show();
          $('#input_3_7').val('');
        });

        $('.opening .job').click(function(){
          if($(this).parent().hasClass('open')){
            return;
          } else {
            if($(this).hasClass('noaction')) 
              return;
            
            $(this).find('.view-j').hide();
            $(this).find('.apply').show();
            prime = $(this).closest('.position');
            $(prime).addClass('open');
            $(prime).find('.details').addClass('go');
            $(prime).find('.close-it').addClass('go');
            var job = $(prime).attr('data-job');
            $('#input_3_7').val(job);
          }
        });

        $("#applybtn").live('click', function(e){
          e.preventDefault();
          $('.applynow').addClass('go');
        });

        $('.applyclose').click(function(){
           $('.applynow').removeClass('go');
            $('#input_3_7').val('');
        });

        $('input[type="file"]').after('<input id="uploadFile" placeholder="" disabled="disabled" /><span class="uploadit"></span>');
        changeInputFile();

        $(document).bind('gform_post_render', function(){
            changeInputFile();
           $('input[type="file"]').after('<input id="uploadFile" placeholder="" disabled="disabled" /><span class="uploadit"></span>');
        });

        function changeInputFile(){
          $('input[type="file"]').change(function(){
            var resume = $(this).val();
            console.log(resume);
            $(this).next('#uploadFile').val(resume);
          });
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
